@import "~bootstrap/scss/functions";

@import "variables";
@import "utilities";
@import "mixins";

@import "bootstrap-config";
@import "mixins-override";

$input-focus-box-shadow: 0 0 0 0.1rem rgb(32 107 196 / 15%);
$form-select-focus-box-shadow: 0 0 0 0.1rem rgb(32 107 196 / 15%);
$btn-focus-box-shadow: 0 0 0 0.1rem rgb(32 107 196 / 15%);