.loader-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	opacity: 0;
	display: flex;
	visibility: hidden;
	pointer-events: none;
	transition: opacity .2s ease;
	border-radius: inherit;
	background: rgba(255, 255, 255, .5);

	& div {
		margin: auto;
		position: relative;
	}

	&.--show {
		opacity: 1;
		visibility: visible;
		pointer-events: auto;
	}
}

.spinner-border {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: text-bottom;
	border: .25em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	-webkit-animation: spinner-border .75s linear infinite;
	animation: spinner-border .75s linear infinite;
}

.sr-only {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: -1px !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	white-space: nowrap !important;
	border: 0 !important;
}

@keyframes spinner-border {
	to { transform: rotate(360deg); }
}
