// stylelint-disable property-no-vendor-prefix
html {
  font-size: 16px;
  //sscroll-behavior: smooth;
  scroll-behavior: auto !important;
  height: 100%;
}

body {
  overflow-y: scroll;
  letter-spacing: $body-letter-spacing;
  touch-action: manipulation;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga" 0;
  position: relative;
  min-height: 100%;

  .content * {
    @include scrollbar;
  }

  @media print {
    background: transparent;
  }
}

