// stylelint-disable declaration-no-important

@include dark-mode {
  & {
    color: $dark-mode-text;
    background-color: $dark-mode-darken;
  }

  .card,
  .card-footer,
  .card-stacked::after,
  .footer:not(.footer-transparent),
  .modal-content,
  .modal-header,
  .dropdown-menu {
    background-color: $dark;
    color: inherit;
  }

  .card {
    border-color: transparent;
  }

  .card-tabs .nav-tabs .nav-link {
    background-color: $dark-mode-darken;
    color: inherit;
  }

  .card-tabs .nav-tabs .nav-link.active {
    background-color: $dark;
    color: inherit;
  }

  .btn-white,
  .form-file-button {
    //@include button-variant($dark-mode-lighten, $border-color, $dark-mode-text, rgba($dark, 0), $border-color, $dark-mode-text);
    background-image: none;
  }

  .form-check-input:not(:checked),
  .form-select,
  .form-file-text,
  .form-control,
  .form-selectgroup-label,
  .form-selectgroup-check,
  .form-imagecheck-figure:before {
    background-color: $dark-mode-darken;
    color: $dark-mode-text;
  }

  .form-control-plaintext {
    color: $dark-mode-text;
  }

  .input-group-flat .input-group-text {
    background-color: $dark-mode-darken;
  }

  .highlight {
    background-color: $dark-mode-darken;
  }

  .avatar {
    background-color: $dark-mode-lighten;
  }

  .avatar-list-stacked .avatar {
    box-shadow: 0 0 0 2px $dark;
  }

  .markdown,
  .markdown>*,
  .btn-close,
  .accordion-button {
    color: inherit;
  }

  .apexcharts-text {
    fill: $dark-mode-text;
  }

  .apexcharts-legend-text {
    color: inherit !important;
  }

  .navbar-brand-autodark {
    @include autodark-image;
  }

  .table thead th,
  .input-group-text {
    background: transparent;
  }

  .list-group-item,
  .form-fieldset {
    border-color: $input-border-color;
  }

  .list-group-header {
    background: $dark-mode-darken;
    border-color: $input-border-color;
  }
}

