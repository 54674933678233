// stylelint-disable declaration-no-important

/**
SOCIAL COLORS
 */
@each $color, $value in $social-colors {
	.bg-social-#{$color} {
		color: #fff !important;
		background: $value !important;
	}

	.text-social-#{$color} {
		color: $value !important;
	}
}

@each $color, $value in $colors {
	.bg-#{$color} {
		color: #fff !important;
		background: $value !important;
	}

	.text-#{$color} {
		color: $value !important;
	}
}


@if $enable-extra-colors {
  @each $color, $value in map-merge($colors, (dark: $dark, muted: $text-muted)) {
    .bg-#{$color} {
      background: $value;
    }

    //.text-#{$color} {
    //  color: $value !important;
    //}
	//
    //.bg-#{$color}-lt {
    //  color: theme-color-darker($value) !important;
    //  background: theme-color-lighter($value, true) !important;
    //}
  }
}
