@import "tabler-core";

@import "custom/nprogress";
@import "custom/loader";
@import "custom/auto-complete";

@import '~sweetalert2/dist/sweetalert2.min.css';

.row-blue {
	color: #206bc4 !important;
	background: rgba(32,107,196,.1) !important;
}

.swal2-container.swal2-center {
	background-color: rgba(0,0,0,.4);
}


.h-100vh {
	height: 100vh;
}

.off {
	opacity: .4;
	//pointer-events: none;
	cursor: no-drop;
}

.custom-phone-input {
	padding-left: 50px !important;
}

.custom-flag {
	position: absolute;
	top: -14px;
	left: -7px;
	margin: 0 !important;
	transform: scale(0.4) !important;
}

.swal2-styled.swal2-confirm {
	@extend .btn;
	@extend .btn-primary;
}

.swal2-styled.swal2-cancel {
	@extend .btn;
	@extend .btn-danger;
}

.modal {
	background: rgba(0,0,0,0.4);
}

.fade-transition-enter, .fade-transition-leave-to {
	opacity: 0;
}
.fade-transition-enter-active, .fade-transition-leave-active {
	transition: opacity .2s !important;
}

.fade-transition-enter-to, .fade-transition-leave {
	opacity: 1;
}

.modal-header {
	align-items: center;
	min-height: 3.5rem;
	background: #ffffff;
	padding: 0 1.5rem 0 1.5rem;
}

.invisible {
	opacity: 0;
	pointer-events: none;
}

.table-hover > tbody > tr:hover {
	--tblr-table-accent-bg: #f4f6fa;
}

.dropdown-menu .router-link-active {
	color: #fff;
	text-decoration: none;
	background-color: rgba(32,107,196,.06);
}

.highlight-search-result {
	background: #ff3535;
	color: #fff;
}

.no-custom-scroll::-webkit-scrollbar {
	//width: auto !important;
	//height: auto !important;
	//transition: none !important;
}

.bg-red-lt {
	//color: #d63939!important;
	background: rgba(214,57,57,.1)!important;
}

.bg-orange-lt {
	//color: #f76707!important;
	background: rgba(247,103,7,.1)!important;
}

.logo-short {
	display: none
}

@media (min-width: 992px) {
	.full-size {
		& .navbar-collapse {
			& .nav-item {
				& .nav-link {
					padding: 0.8rem 1.2rem !important;

					& .nav-link-title {
						display: none;
					}
				}
			}
		}

		& .navbar-vertical {
			width: 4rem;

			& .logo {
				display: none;
			}

			& .logo-short {
				display: inline;
			}
		}

		& .navbar-expand-md {
			-webkit-margin-start: 4rem !important;
			margin-inline-start: 4rem !important;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			z-index: 100;
		}

		& .footer {
			position: fixed;
			bottom: 0;
			left: 4rem;
			right: 0;
			padding: 1rem 0 2rem;
			background-color: #f4f6fa;
		}

		.content-header {
			position: fixed;
			top: 50px;
			left: 4rem;
			right: 0;
			padding-top: 1.25rem;
			//padding-bottom: 1.25rem;
			background-color: #f4f6fa;
			z-index: 5;
		}

		& .content {
			//-webkit-margin-start: 4rem !important;
			margin-inline-start: 4rem !important;
			//margin-top: 8rem !important;
			margin-bottom: 1rem !important;
		}
	}
}

.no-use {
	user-select: none;
}

.no-pointer {
	pointer-events: none;
}

.square {
	overflow: hidden;

	&.--maneuver {
		max-height: 80px;
	}
}

.square:before {
	content: "";
	padding-top: 50%;
	float: left;
}



.btn-icon-outline-primary {
	svg, g, path, rect {
		fill: var(--tblr-primary);
	}

	&:hover {
		svg, g, path, rect {
			fill: #fff;
		}
	}
}

.action {

	&.--add {
		color: #2fb344 !important;
		background: rgba(47,179,68,.1) !important;
	}

	&.--add-done {
		color: #2fb344 !important;
		background: rgba(47,179,68,0);
		animation: fadeOutAdd linear 1s 1;
	}

	&.--edit {
		color: #f59f00 !important;
		background: rgba(245,159,0,.1) !important;
	}

	&.--edit-done {
		color: #f59f00 !important;
		background: rgba(245,159,0,0);
		animation: fadeOutEdit linear 1s 1;
	}
}

@keyframes fadeOutAdd {
	0% { background: rgba(47,179,68,.1) }
	100% { background: rgba(47,179,68,0) }
}

@keyframes fadeOutEdit {
	0% { background: rgba(245,159,0,.1) }
	100% { background: rgba(245,159,0,0) }
}

//table, .table {
//	--tblr-table-bg: transparent;
//	--tblr-table-accent-bg: #f4f6fa;
//	--tblr-table-striped-color: inherit;
//	--tblr-table-striped-bg: #f4f6fa;
//	--tblr-table-active-color: inherit;
//	--tblr-table-active-bg: rgba(0,0,0,0.1);
//	--tblr-table-hover-color: inherit;
//	--tblr-table-hover-bg: rgba(0,0,0,0.075);
//}
//
//table tbody tr {
//	--tblr-table-accent-bg: #fff;
//}